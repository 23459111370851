<template>
  <div id="spas-service">
    <Jumbotron
      header="Spas - Service"
      lead="You have a spa to relax, so let us worry about keeping you that way."
      bgImagePath="/media/static/water-wallpaper.jpg"
    >
    </Jumbotron>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";

export default {
  name: "Service",

  components: {
    Jumbotron
  },

  data() {
    return {
      images: []
    };
  }
};
</script>

<style scoped lang="scss">
</style>
