<template>
  <b-jumbotron
    fluid
    class="wallpaper"
    v-bind:style="{ backgroundImage: `url( ${bgImagePath} )` }"
  >
    <h1 class="header text-white">{{ header }}</h1>
    <p class="lead mr-4 text-white">
      Let's start building your dream from the deep-end up.
    </p>
  </b-jumbotron>
</template>

<script>
export default {
  name: "Jumbotron",

  props: {
    header: String,
    lead: String,
    bgImagePath: String
  },

  components: {},

  data() {
    return {};
  },

  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wallpaper {
  background-position: center center;
  background-size: cover;
}

.header {
  font-size: $base-font-size * 2.5;
  font-family: "Permanent Marker", cursive;
}

.lead {
  font-size: $base-font-size * 1.1;
  font-family: "Lato", sans-serif;
}
</style>
